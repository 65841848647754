import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {jsonToParamsRequest} from "../libs/util";
import {environment} from "../../environments/environment";
const {apiUrl} = environment;
@Injectable({
  providedIn: 'root'
})
export class MessengerService {

  endPoint = 'messenger/ia-chat';

  constructor(private http: HttpClient) {
  }

  getChatHistory(params?: any) {
    const paramsQuery = params ? jsonToParamsRequest(params) : null;
    return this.http.get(`${apiUrl}/${this.endPoint}/getChatHistory${paramsQuery ? `?${paramsQuery}` : ''}`)
  }

  sendMessageIAConversation(model: any) {
    return this.http.post(`${apiUrl}/${this.endPoint}/sendMessageIAConversation`,model)
  }
}
