import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-chat-item',
  templateUrl: './chat-item.component.html',
  styleUrl: './chat-item.component.css'
})
export class ChatItemComponent implements OnInit{
  @Input() showHeader: boolean | undefined;
  @Input() name: string | undefined;
  @Input() type: string | undefined;
  @Input() message: string | undefined;
  @Input() buttons: any | undefined;
  @Input() url: any | undefined;

  ngOnInit(): void {
  }

}
