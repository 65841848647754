import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {initFlowbite} from 'flowbite';
import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";
import {SessionService} from "../services/session.service";
import {MessengerService} from "../services/messenger.service";

@Component({
  selector: 'app-chat-widget',
  templateUrl: './chat-widget.component.html',
  styleUrls: ['./chat-widget.component.css'],
  animations: [
    trigger('fadeOut', [
      state('void', style({opacity: 0})), // Estilo cuando el elemento desaparece
      transition(':leave', [animate('0.5s ease-out')]), // Animación para desaparecer
    ]),
    trigger('fadeIn', [
      state('void', style({opacity: 0})), // Estilo inicial cuando el elemento no está en el DOM
      transition(':enter', [animate('0.5s ease-in')]), // Animación para aparecer
    ]),
    trigger('flapInOut', [
      // FlapIn (Entrada)
      transition(':enter', [
        animate(
          '0.6s ease-out',
          keyframes([
            style({transform: 'rotateY(90deg)', opacity: 0, offset: 0}),
            style({transform: 'rotateY(20deg)', opacity: 0.8, offset: 0.7}),
            style({transform: 'rotateY(0deg)', opacity: 1, offset: 1}),
          ])
        ),
      ]),
      // FlapOut (Salida)
      transition(':leave', [
        animate(
          '0.6s ease-in',
          keyframes([
            style({transform: 'rotateY(0deg)', opacity: 1, offset: 0}),
            style({transform: 'rotateY(70deg)', opacity: 0.5, offset: 0.3}),
            style({transform: 'rotateY(90deg)', opacity: 0, offset: 1}),
          ])
        ),
      ]),
    ]),
  ],
})
export class ChatWidgetComponent implements OnInit {
  @ViewChild('scrollable') scrollable: ElementRef | undefined;
  sessionId: string | undefined;
  isChatVisible = false;
  isWriting = false;
  userMessage = '';
  messages: any = [
    {
      code: '',
      name: 'Gallito',
      actor: 'assistant',
      body: 'Holi'
    }
  ];

  constructor(
    private sessionService: SessionService,
    private messengerService: MessengerService,
  ) {

  }

  ngOnInit(): void {
    initFlowbite()
    this.sessionId = this.sessionService.getSessionData()
    this.loadHistory()
  }

  loadHistory(){
    this.messages = this.sessionService.chatGetMessages()
    this.scrollToBottom()
  }

  scrollToBottom(): void {
    try {
      setTimeout(() => {
        let objDiv: any = document.getElementById("chat-messages");
        if (objDiv)
          objDiv.scrollTop = objDiv?.scrollHeight;

        const inputObj: any = document.getElementById("txtMessage");
        inputObj.focus()
      }, 100)
    } catch (err) {
    }
  }

  toggleChat() {
    this.isChatVisible = !this.isChatVisible;
    this.scrollToBottom()
  }

  sendMessage() {
    if (this.userMessage.trim()) {
      this.isWriting = true;
      // Agregar mensaje del usuario
      const message = {
        code: null,
        name: 'Tu',
        actor: 'user',
        body: this.userMessage
      }
      this.messages.push(message);
      this.sessionService.chatSaveMessage(message)

      this.scrollToBottom();
      this.messengerService.sendMessageIAConversation({
        application: {code: '89a0935a-5453-4938-8571-06196338762b'},
        conversation: {
          sessionId: this.sessionId,
        },
        message: {
          body: this.userMessage
        }
      }).subscribe({
        next: (data: any) => {
          this.userMessage = '';
          data.name ='Gallito'
          this.sessionService.chatSaveMessage(data)
          this.messages.push(data);
          this.scrollToBottom();
          this.isWriting = false;

        },error: ()=>{
          this.isWriting = false;
          const message ={
            code: null,
            name: '',
            actor: 'assistant',
            body: 'Lo siento no puden entender tu mensaje.'
          }
          this.messages.push();
          this.sessionService.chatSaveMessage(message)
        }
      })
    }
  }
}
