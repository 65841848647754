import {Injectable} from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import {v4 as uuidv4} from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  sessionKey = 'D070nD0r7uH3k1'
  sessionMessageKey = 'D070nD0r7uH3k1Mesg'

  constructor(private cookieService: CookieService) {
  }

  getSessionData() {
    console.log('Cokie')
    let sessionId = this.cookieService.get(this.sessionKey);
    if (sessionId?.length == 0) {
      this.cookieService.set(this.sessionKey, uuidv4(), {expires: 1, sameSite: 'Lax'});
      sessionId = this.cookieService.get(this.sessionKey);
    }

    return sessionId
  }

  chatSaveMessage({code, name, actor, body}: any) {
    const list: any = this.chatGetMessages();
    list.push({code, name, actor, body})
    sessionStorage.setItem(this.sessionMessageKey, JSON.stringify(list));
  }

  chatGetMessages() {
    let dataJson: any = sessionStorage.getItem(this.sessionMessageKey)
    if (dataJson?.length > 0) {
      return JSON.parse(dataJson)
    }
    return []
  }
}
