<div class="flex gap-2.5"
     [ngClass]="type=='user'?'client-chat-bubble items-start':'server-chat-bubble flex-row-reverse'">
  <div class="flex flex-col gap-1 max-w-[320px] chat-header">
    <div class="space-x-2" *ngIf="type=='user' && showHeader">
      <span class="text-sm font-semibold text-gray-900 dark:text-white">{{name}}</span>
      <span class="text-sm font-normal text-gray-500 dark:text-gray-400">11:46</span>
    </div>
    <div class=" space-x-2" *ngIf="type!='user' && showHeader">
      <span class="text-sm font-normal text-gray-500 dark:text-gray-400">11:46</span>
      <span class="text-sm font-semibold text-gray-900 dark:text-white">Bonnie Green</span>
    </div>
    <div [ngClass]="type=='user'?'rounded-e-xl rounded-es-xl':'rounded-s-xl rounded-se-xl'"
         class="chat-text flex flex-col leading-1.5 p-2.5 border-gray-200 bg-gray-100 dark:bg-gray-700">
      <p class="text-sm font-normal text-gray-900 dark:text-white">
        {{message}}
      </p>
    </div>
  </div>
</div>
