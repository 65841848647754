<button id="chat-widget"
        data-tooltip-target="tooltip-top" data-tooltip-placement="left"
        class="transition ease-in-out delay-150 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        (click)="toggleChat()">
  <i class="fa-regular fa-message"></i>
</button>

<div id="tooltip-top" role="tooltip"
     class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
  Escríbeme
  <div class="tooltip-arrow" data-popper-arrow></div>
</div>

<div id="chat-container" *ngIf="isChatVisible"
     @flapInOut
     class="max-w-sm bg-white border border-gray-200
     rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
  <div class="flex justify-end px-4 pt-4 chat-header">
    <div class="grid grid-cols-5">
      <div class="col-span-2">
        <img class="img-chat-header" src="assets/images/metro/gallito-solo-azul.png" alt="">
      </div>
      <div class="col-span-2">Gallito de la catedral</div>
      <div style="text-align: right">
        <button type="button"
                (click)="isChatVisible=false"
                class="text-blue-700
        hover:bg-gray-300 hover:blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300
        font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500
        dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
          <i class="fa-solid fa-minus"></i>
        </button>
      </div>
    </div>

  </div>
  <div id="chat-messages" #scrollable class="p-2">
    <app-chat-item *ngFor="let message of messages"
                   [type]="message.actor"
                   [message]="message.body"
                   [name]="message.name"
    ></app-chat-item>
    <div *ngIf="isWriting" class="" style="margin: 10px; text-align: right">
      <span
        class="bg-blue-100 text-blue-800 text-sm font-medium
      me-2 px-2.5 py-2.5 rounded dark:bg-blue-900 dark:text-blue-300">
         <i class="animate-bounce fa-solid fa-comment-dots"></i>
      </span>
    </div>
  </div>

  <div class="p-2">
    <div class="flex">
      <div class="relative w-full">
        <input type="search" id="txtMessage" [disabled]="isWriting" [(ngModel)]="userMessage"
               class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg rounded-s-gray-100 rounded-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
               placeholder="Aquí tu mensaje" required/>
        <button type="submit" (click)="sendMessage()"
                class="absolute top-0 end-0 p-2.5 h-full text-sm font-medium text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          <i class="fa-regular fa-paper-plane"></i>
        </button>
      </div>
    </div>
    <div class="text-center p-3 grid grid-cols-6">
      <div class="col-span-6 items-center">
        <p class="text-sm items-center text-gray-900 dark:text-white">
          Powered By <a target="_blank"
          class="inline-flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline"
          href="https://ingenius-soft.com">Ingenius Soft</a>
        </p>
      </div>
    </div>
  </div>
</div>
